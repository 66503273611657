import React, { useState, useEffect, useCallback } from 'react';
import { createClient } from 'contentful';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import './ContentfulComponent.css';

function formatNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2) + 'B';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num;
  }
}

function formatPrice(n) {
  if (n === 0) {
    return "0.00"; // Explicit handling for zero
  }
  if (!n) {
    return ""; // Handling for null or undefined
  }

  if (n >= 10) {
    // For prices >= 10, show with two decimal places
    return n.toFixed(2);
  } else if (n >= 0.1) {
    // For prices between 0.1 and 10, show with three decimal places
    return n.toFixed(3);
  } else if (n >= 0.001) {
    // For prices between 0.001 and 0.1, show with three decimal places
    return n.toFixed(4);
  } else {
    // For prices below 0.001, handle with subscript
    const numberString = n.toExponential(); // Convert number to scientific notation
    const [mantissa, exponent] = numberString.split('e-');
    const leadingZeros = parseInt(exponent, 10) - 1; // Calculate leading zeros based on the exponent

    // Extract first three significant digits from mantissa
    const significantDigits = mantissa.replace('.', '').slice(0, 3);

    // Convert the count of leading zeros to subscript
    const subscript = leadingZeros.toString().split('').map(digit => String.fromCharCode(8320 + Number(digit))).join('');

    // Format with the subscript and the first three significant digits
    return `0.0${subscript}${significantDigits}`;
  }
}

const client = createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

const ContentfulComponent = () => {
  const [data, setData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState('');
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const filterAndRankData = useCallback((categoryId, items) => {
    const filteredTokens = items.filter(token => token.fields.category && token.fields.category.sys.id === categoryId && token.fields.symbol);
    const rankedTokens = rankTokens(filteredTokens);
    setFilteredData(rankedTokens);
  }, []);

  const fetchData = useCallback(async () => {
    console.log('Fetching data started at:', new Date().toLocaleTimeString());
    try {
      const entriesResponse = await client.getEntries({ content_type: 'token', limit : 1000 });
      const categoriesResponse = await client.getEntries({ content_type: 'category' });
      console.log('Data fetched at:', new Date().toLocaleTimeString());
  
      const items = entriesResponse.items;
      const categoryItems = categoriesResponse.items
        .map(category => ({
          id: category.sys.id,
          name: category.fields.name,
          order: category.fields.order
        }))
        .sort((b, a) => b.order - a.order);
  
      setData(items);
      setCategories(categoryItems);
      //setLastUpdated(new Date().toLocaleTimeString());
  
      if (categoryItems.length > 0) {
        if (!activeTab || !categoryItems.find(category => category.id === activeTab)) {
          // If no activeTab is set or the current activeTab no longer exists in the categories list
          const firstCategoryId = categoryItems[0].id;
          setActiveTab(firstCategoryId);
          filterAndRankData(firstCategoryId, items);
        } else {
          // Refresh data for the existing activeTab
          filterAndRankData(activeTab, items);
        }
      }
    } catch (error) {
      console.error('Fetch data error:', error);
    }
  }, [activeTab, filterAndRankData]);
  
  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 10000); // Adjust interval as needed
  
    return () => clearInterval(intervalId);
  }, [fetchData]);

  const getFormattedLastUpdated = () => {
    const now = new Date();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const pastMinuteMark = minutes % 5;
    const isExactFiveMinuteMark = pastMinuteMark === 0 && seconds === 0;
  
    if (isExactFiveMinuteMark) {
      return "0 seconds ago";
    } else if (pastMinuteMark === 0) {
      // Handling singular and plural for seconds
      const secondText = seconds === 1 ? "second" : "seconds";
      return `${seconds} ${secondText} ago`;
    } else {
      // Handling singular and plural for minutes
      const minuteText = pastMinuteMark === 1 ? "minute" : "minutes";
      return `${pastMinuteMark} ${minuteText} ago`;
    }
  };
  
  useEffect(() => {
    const updateTimestamp = () => {
      setLastUpdated(getFormattedLastUpdated());
    };
  
    updateTimestamp(); // Set immediately on component mount
    const intervalId = setInterval(updateTimestamp, 1000); // Update every second
  
    return () => clearInterval(intervalId); // Clean up on unmount
  }, []);

  const handleTabClick = useCallback((categoryId) => {
    setActiveTab(categoryId);
    filterAndRankData(categoryId, data);
  }, [data, filterAndRankData]);

  const rankTokens = (tokens) => {
    return tokens.sort((a, b) => (b.fields.fdv || 0) - (a.fields.fdv || 0))
      .map((token, index) => ({
        ...token,
        rank: index + 1
      }));
  };

  return (
    <div className="container">
      <div className="last-updated">
      Last updated: {lastUpdated}
      </div>
      <h1 className="title">Rank.meme</h1>
      <div className="tabs">
        {categories.map(category => (
          <button
            key={category.id}
            className={activeTab === category.id ? 'active' : ''}
            onClick={() => handleTabClick(category.id)}
          >
            {category.name}
          </button>
        ))}
      </div>
      <div className="datatable-container">
        <div className="table-responsive">
          <table className="datatable">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Ticker</th>
                <th className="mobile-hide">Name</th>
                <th>FDV</th>
                <th>Price</th>
                <th className="mobile-hide">5m</th>
                <th>1h</th>
                <th className="mobile-hide">6h</th>
                <th>24h</th>
                <th className="mobile-hide">Liquidity</th>
                <th className="mobile-hide">Vol (24h)</th>
                <th className="mobile-hide">Txs (24h)</th>
                <th>Dex</th>
                <th className="mobile-hide">X</th>
                <th className="mobile-hide">TG</th>
                <th className="mobile-hide">Site</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => (
                <tr key={item.sys.id}>
                  <td>{item.rank}</td>
                  <td>{item.fields.symbol}</td>
                  <td className="mobile-hide">{item.fields.name}</td>
                  <td>{formatNumber(item.fields.fdv)}</td>
                  <td>{formatPrice(item.fields.priceUsd)}</td>
                  <td className="mobile-hide" style={{ color: item.fields.priceChange5m < 0 ? 'red' : 'green' }}>{item.fields.priceChange5m}%</td>
                  <td style={{ color: item.fields.priceChange1h < 0 ? 'red' : 'green' }}>{item.fields.priceChange1h}%</td>
                  <td className="mobile-hide" style={{ color: item.fields.priceChange6h < 0 ? 'red' : 'green' }}>{item.fields.priceChange6h}%</td>
                  <td style={{ color: item.fields.priceChange24h < 0 ? 'red' : 'green' }}>{item.fields.priceChange24h}%</td>
                  <td className="mobile-hide">{formatNumber(item.fields.liquidity)}</td>
                  <td className="mobile-hide">{formatNumber(item.fields.volume24h)}</td>
                  <td className="mobile-hide">{item.fields.transactions24h}</td>
                  <td>
                    {item.fields.dexscreener ? (
                      <a href={item.fields.dexscreener} target="_blank" rel="noopener noreferrer" aria-label="Open link">
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ color: 'white' }} />
                      </a>
                    ) : null}
                  </td>
                  <td className="mobile-hide">
                    {item.fields.twitter ? (
                      <a href={item.fields.twitter} target="_blank" rel="noopener noreferrer" aria-label="Open link">
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ color: 'white' }} />
                      </a>
                    ) : null}
                  </td>
                  <td className="mobile-hide">
                    {item.fields.telegram ? (
                      <a href={item.fields.telegram} target="_blank" rel="noopener noreferrer" aria-label="Open link">
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ color: 'white' }} />
                      </a>
                    ) : null}
                  </td>
                  <td className="mobile-hide">
                    {item.fields.website ? (
                      <a href={item.fields.website} target="_blank" rel="noopener noreferrer" aria-label="Open link">
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ color: 'white' }} />
                      </a>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ContentfulComponent;