import React from 'react';
import ContentfulComponent from './ContentfulComponent';
import './App.css';
import { Analytics } from "@vercel/analytics/react"
const App = () => {
  return (
    <div>
      <ContentfulComponent />
      <Analytics />
    </div>
  );
};

export default App;